<template>
  <div id="app">
    <router-view v-if="!$isMobile() && $store.state.allowed" />
    <div v-else-if="!$isMobile() && !$store.state.allowed">
      <Previsit></Previsit>
    </div>
    <div v-else-if="$isMobile()" class="mobile-only">
      <div class="content">
        For the best experience of 'Whodunnit at the Coliseum?', please visit
        this link on your laptop or desktop computer.<br /><br />Thank you.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {
    Previsit: () => import("@/components/Previsit")
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "Franchise";
  src: url("./assets/franchise-bold-webfont.woff2") format("woff2"),
    url("./assets/franchise-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.mobile-only {
  width: 100vw;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  background: #efeeea;
  position: relative;

  .content {
    width: 100%;
    background: url(./assets/mobile-bg.png) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: 100% 100%;
    padding: 25px;
    font-size: 2.8em;
    line-height: 1.3em;
    font-family: "Franchise";
    color: #fff;
    height: 100%;
  }
}
</style>
