<template>
  <v-app>
    <v-main
      :class="{
        experiencestarted: Experience.Started,
        optionsactive: displayOptions,
        loaded: Experience.Loaded
      }"
    >
      <div class="centered">
        <a
          class="download-programme"
          href="https://whodunnit.coliseum.org.uk/Whodunnit-at-the-Coliseum-Programme.pdf"
          target="_blank"
        >
          <img
            src="@/assets/download-programme.png"
            alt="Download the show programme"
            width="100%"
          />
        </a>

        <transition name="fade">
          <div v-show="Experience.StartVideos">
            <v-responsive :aspect-ratio="41 / 35">
              <div class="video-block">
                <v-responsive :aspect-ratio="16 / 9">
                  <VideoHolder
                    :Experience="Experience"
                    :meta="videoMeta"
                    :allMeta="VideoData"
                    @UpdateTime="UpdateTime"
                    @UpdateId="SetCurrentVideo"
                    @selectOption="VideoDefault"
                    :option-time="optionTimeRemaining"
                    :next-video="NextVideo"
                    :next-player="NextPlayer"
                  ></VideoHolder>
                </v-responsive>
              </div>
              <options
                :meta="videoMeta"
                :active="displayOptions"
                @selectOption="VideoSelected"
                :time="optionTimeRemaining"
                :next-player="NextPlayer"
              ></options>
            </v-responsive>
          </div>
        </transition>
        <transition name="fade">
          <div v-show="!Experience.Started">
            <Welcome @start="Start()" :loaded="Experience.Loaded"></Welcome>
          </div>
        </transition>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "TheShow",
  title: "The Show",
  components: {
    Welcome: () => import("@/components/Welcome"),
    VideoHolder: () => import("@/components/VideoHolder"),
    Options: () => import("@/components/Options")
  },
  data() {
    return {
      Experience: {
        Started: false,
        StartVideos: false,
        Loaded: false
      },
      CurrentVideo: 1,
      CurrentTime: 0,
      NextVideo: 2,
      NextPlayer: 2,
      // Don't forget phantom node! "" to start array!!
      VideoData: [
        "",
        {
          Id: 1,
          Video: 1,
          Player: 1,
          Start: 0,
          OpAText: "Stalls",
          OpAId: 2,
          OpBText: "Dress Circle",
          OpBId: 3,
          OpAppearSecs: 142
          // OpAppearSecs: 142
        },
        {
          Id: 2,
          Video: 2,
          Player: 2,
          Start: 165,
          OpAText: "Talk to Michael",
          OpAId: 4,
          OpBText: "Talk to Grace",
          OpBId: 5,
          OpAppearSecs: 204
        },
        {
          Id: 3,
          Video: 3,
          Player: 3,
          Start: 216,
          OpAText: "Talk to Stanley",
          OpAId: 7,
          OpBText: "Talk to Ethel",
          OpBId: 6,
          OpAppearSecs: 256
        },
        {
          Id: 4,
          Video: 4,
          Player: 1,
          Start: 268,
          OpAText: "Are you looking forward to the show?",
          OpAId: 8,
          OpBText: "May I buy you a drink?",
          OpBId: 9,
          OpAppearSecs: 328
        },
        {
          Id: 5,
          Video: 10,
          Player: 3,
          Start: 590,
          OpAText: "Will I have seen you in anything?",
          OpAId: 10,
          OpBText: "Are you the girl in the rat poison advert?",
          OpBId: 11,
          OpAppearSecs: 678
        },
        {
          Id: 6,
          Video: 15,
          Player: 1,
          Start: 964,
          OpAText: "Do you live nearby?",
          OpAId: 12,
          OpBText: "Are you looking forward to the show?",
          OpBId: 13,
          OpAppearSecs: 1043
        },
        {
          Id: 7,
          Video: 21,
          Player: 2,
          Start: 1416,
          OpAText: "Have you been here before?",
          OpAId: 14,
          OpBText: "Do you live nearby?",
          OpBId: 15,
          OpAppearSecs: 1504
        },
        {
          Id: 8,
          Video: 5,
          Player: 2,
          Start: 345,
          OpAText: "How often do you come here?",
          OpAId: 16,
          OpBText: "Do you know anyone else in the audience tonight?",
          OpBId: 17,
          OpAppearSecs: 397
        },
        {
          Id: 9,
          Video: 6,
          Player: 3,
          Start: 415,
          OpAText: "How often do you come here?",
          OpAId: 18,
          OpBText: "Do you know anyone else in the audience tonight?",
          OpBId: 19,
          OpAppearSecs: 480
        },
        {
          Id: 10,
          Video: 11,
          Player: 1,
          Start: 692,
          OpAText: "Have you been here before?",
          OpAId: 20,
          OpBText: "Do you know the way to the stalls from here?",
          OpBId: 21,
          OpAppearSecs: 768
        },
        {
          Id: 11,
          Video: 12,
          Player: 2,
          Start: 783,
          OpAText: "Have you been here before?",
          OpAId: 22,
          OpBText: "Do you know the way to the stalls from here?",
          OpBId: 23,
          OpAppearSecs: 848
        },
        {
          Id: 12,
          Video: 16,
          Player: 2,
          Start: 1060,
          OpAText: "May I buy you a drink?",
          OpAId: 24,
          OpBText: "Excuse yourself to leave",
          OpBId: 25,
          OpAppearSecs: 1115
        },
        {
          Id: 13,
          Video: 17,
          Player: 3,
          Start: 1132,
          OpAText: "May I buy you a drink?",
          OpAId: 26,
          OpBText: "Excuse yourself to leave",
          OpBId: 27,
          OpAppearSecs: 1206
        },
        {
          Id: 14,
          Video: 22,
          Player: 1,
          Start: 1523,
          OpAText: "What's wrong with your sister?",
          OpAId: 28,
          OpBText: "Do you know anyone else here?",
          OpBId: 29,
          OpAppearSecs: 1571
        },
        {
          Id: 15,
          Video: 23,
          Player: 3,
          Start: 1589,
          OpAText: "What's wrong with your sister?",
          OpAId: 30,
          OpBText: "Do you know anyone else here?",
          OpBId: 31,
          OpAppearSecs: 1640
        },
        {
          Id: 16,
          Video: 7,
          Player: 1,
          Start: 495,
          OpAText: "Auto to",
          OpAId: 32,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 532,
          AutoContinue: true
        },
        {
          Id: 17,
          Video: 8,
          Player: 3,
          Start: 545,
          OpAText: "Auto to",
          OpAId: 32,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 579,
          AutoContinue: true
        },
        {
          Id: 18,
          Video: 7,
          Player: 1,
          Start: 495,
          OpAText: "Auto to",
          OpAId: 32,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 532,
          AutoContinue: true
        },
        {
          Id: 19,
          Video: 8,
          Player: 2,
          Start: 545,
          OpAText: "Auto to",
          OpAId: 33,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 579,
          AutoContinue: true
        },
        {
          Id: 20,
          Video: 13,
          Player: 2,
          Start: 863,
          OpAText: "Auto to",
          OpAId: 33,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 906,
          AutoContinue: true
        },
        {
          Id: 21,
          Video: 14,
          Player: 3,
          Start: 917,
          OpAText: "Auto to",
          OpAId: 32,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 952,
          AutoContinue: true
        },
        {
          Id: 22,
          Video: 13,
          Player: 1,
          Start: 863,
          OpAText: "Auto to",
          OpAId: 32,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 906,
          AutoContinue: true
        },
        {
          Id: 23,
          Video: 14,
          Player: 3,
          Start: 917,
          OpAText: "Auto to",
          OpAId: 33,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 952,
          AutoContinue: true
        },
        {
          Id: 24,
          Video: 18,
          Player: 1,
          Start: 1223,
          OpAText: "Auto to",
          OpAId: 34,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 1305,
          AutoContinue: true
        },
        {
          Id: 25,
          Video: 19,
          Player: 3,
          Start: 1317,
          OpAText: "Auto to",
          OpAId: 35,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 1403,
          AutoContinue: true
        },
        {
          Id: 26,
          Video: 18,
          Player: 1,
          Start: 1223,
          OpAText: "Auto to",
          OpAId: 34,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 1305,
          AutoContinue: true
        },
        {
          Id: 27,
          Video: 19,
          Player: 2,
          Start: 1317,
          OpAText: "Auto to",
          OpAId: 35,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 1404,
          AutoContinue: true
        },
        {
          Id: 28,
          Video: 24,
          Player: 2,
          Start: 1658,
          OpAText: "Auto to",
          OpAId: 35,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 1700,
          AutoContinue: true
        },
        {
          Id: 29,
          Video: 25,
          Player: 3,
          Start: 1712,
          OpAText: "Auto to",
          OpAId: 34,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 1761,
          AutoContinue: true
        },
        {
          Id: 30,
          Video: 24,
          Player: 1,
          Start: 1648,
          OpAText: "Auto to",
          OpAId: 34,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 1700,
          AutoContinue: true
        },
        {
          Id: 31,
          Video: 25,
          Player: 2,
          Start: 1712,
          OpAText: "Auto to",
          OpAId: 35,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 1761,
          AutoContinue: true
        },
        {
          Id: 32,
          Video: 9,
          Player: 2,
          Start: 1773,
          OpAText: "Ending",
          OpAId: null,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 2672,
          Ending: true
        },
        {
          Id: 33,
          Video: 9,
          Player: 1,
          Start: 1773,
          OpAText: "Ending",
          OpAId: null,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 2672,
          Ending: true
        },
        {
          Id: 34,
          Video: 20,
          Player: 2,
          Start: 2686,
          OpAText: "Ending",
          OpAId: null,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 3478,
          Ending: true
        },
        {
          Id: 35,
          Video: 20,
          Player: 1,
          Start: 2686,
          OpAText: "Ending",
          OpAId: null,
          OpBText: "",
          OpBId: null,
          OpAppearSecs: 3478,
          Ending: true
        }
      ],
      OptionsClicked: false
    };
  },
  methods: {
    Start() {
      this.Experience.Started = true;
      setTimeout(() => {
        this.Experience.StartVideos = true;
      }, 1000);
      this.$gtag.event("user_starts_show");
    },
    VideoSelected(val) {
      let next = this.VideoData[val].Player;
      this.NextVideo = val;
      this.NextPlayer = next;
      this.OptionsClicked = true;
      console.log(
        "Home:VideoSelected() User clicked to play Block: " +
          val +
          " in Player: " +
          next
      );
    },
    VideoDefault(val) {
      let next = this.VideoData[val].Player;
      this.NextVideo = val;
      this.NextPlayer = next;
      console.log(
        "Home:VideoDefault() Default set to play Block: " +
          val +
          " in Player: " +
          next
      );
    },
    UpdateTime(val) {
      this.CurrentTime = val;
    },
    SetCurrentVideo(val) {
      this.CurrentVideo = val;
    }
  },
  computed: {
    videoMeta() {
      // Store video timing info
      let video = this.VideoData[this.CurrentVideo];
      return video;
    },
    displayOptions() {
      let noOps = this.videoMeta.AutoContinue;
      let ending = this.videoMeta.Ending;
      let t1 = this.videoMeta.OpAppearSecs - 1;
      let t2 = this.videoMeta.OpAppearSecs + 10;
      if (
        this.CurrentTime > t1 &&
        this.CurrentTime < t2 &&
        !noOps &&
        !ending &&
        !this.OptionsClicked
      ) {
        return true;
      } else {
        return false;
      }
    },
    optionTimeRemaining() {
      let t2 = this.videoMeta.OpAppearSecs + 10;
      return t2 - this.CurrentTime;
    }
  },
  watch: {
    CurrentVideo(val) {
      // Update for default next option
      let op = this.VideoData[val].OpAId;
      if (op) {
        this.NextPlayer = this.VideoData[op].Player;
        this.NextVideo = op;
        this.OptionsClicked = false;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.Experience.Loaded = true;
    }, 300);
  }
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

main {
  height: 100vh;
  position: relative;
  display: block;
  transform: scale(4);
  transition: all 6s ease-in-out;

  &.loaded {
    transform: scale(1);
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../assets/newspaper-bg.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    filter: invert(20%) sepia(0.8) brightness(0.7);
    -webkit-filter: invert(20%) sepia(0.8) brightness(0.7);
    transition: 4s ease-out;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../assets/newspaper-no-lines.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    transition: 8s ease-out;
  }

  &.experiencestarted {
    &:after {
      opacity: 0;
      filter: invert(40%) sepia(0.6);
      -webkit-filter: invert(40%) sepia(0.6);
    }
    .download-programme {
      filter: invert(20%) sepia(0.8) brightness(0.7);
      -webkit-filter: invert(20%) sepia(0.8) brightness(0.7);
    }
  }

  &.optionsactive:before {
    filter: invert(0%) sepia(0.4) brightness(0.7);
    -webkit-filter: invert(0%) sepia(0.4) brightness(0.7);
    opacity: 0.7;
  }

  .video-block {
    position: relative;
    top: 7%;
    margin: 7px 7px 7px 14px;
  }
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-52%));
  width: 55.2%;
  //min-width: 55.2%;
  z-index: 1;
}

.video-embed {
  width: 100%;
}

.download-programme {
  display: block;
  position: absolute;
  top: 4px;
  right: -7px;
  width: 42%;
  z-index: 100;
  transition: 1s ease-out;

  &:hover {
    filter: none !important;
    -webkit-filter: none !important;
  }
}
</style>
