import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

//init store
const store = new Vuex.Store({
  state: {
    captions: false,
    viewingdate: null,
    allowed: false,
    earlylate: null
  },
  mutations: {
    enablecc(state) {
      state.captions = true;
    },
    disablecc(state) {
      state.captions = false;
    }
  }
});

export default store;
