import Vue from "vue";
import App from "./App.vue";

import vueVimeoPlayer from "vue-vimeo-player";
import vuetify from "./plugins/vuetify";
import vmodal from "vue-js-modal";
import VuePageTitle from "vue-page-title";
import VueMobileDetection from "vue-mobile-detection";
import VueGtag from "vue-gtag";
import store from "./store";

Vue.use(VueGtag, {
  config: { id: "G-PBJVHPZB4D" }
});
Vue.use(VuePageTitle, {
  suffix: "|| Whodunnit at the Coliseum?"
});

import router from "./router";

Vue.use(vueVimeoPlayer);
Vue.use(vmodal);
Vue.use(VueMobileDetection);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
