import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue")
  },
  {
    path: "/map",
    name: "Map",
    component: () => import("../views/Map.vue")
  },
  {
    path: "/guess-who",
    name: "Guess Who",
    component: () => import("../views/GuessWho.vue")
  },
  {
    path: "/ending",
    name: "EndingSuccess",
    component: () => import("../views/EndingSuccess.vue")
  },
  {
    path: "/oops",
    name: "EndingFail",
    component: () => import("../views/EndingFail.vue")
  },
  {
    path: "/programme",
    name: "Programme",
    // Whodunnit-at-the-Coliseum-Programme.pdf
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        "https://whodunnit.coliseum.org.uk/Whodunnit-at-the-Coliseum-Programme.pdf"
      );
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let date;
  let allowed = false;
  let val = to.query.d;
  console.log(val);
  switch (val) {
    case "uSOaBaxwB9bWMoO":
      store.state.allowed = true;
      allowed = true;
      break;
    case "james":
      store.state.allowed = true;
      allowed = true;
      break;
    case "amQxSEg3NEtoRE1YVi9XazdUZllSZz07":
      date = "2021-04-07";
      break;
    case "amQxSEg3NEtoRE1YVi9XazdUZllSZz08":
      date = "2021-04-08";
      break;
    case "amQxSEg3NEtoRE1YVi9XazdUZllSZz09":
      date = "2021-04-09";
      break;
    case "SFRPZTZIWWxXcmJFN0diYllPenIydz09":
      date = "2021-04-10";
      break;
    case "OHlrbjFrdVlhdEtoQjZ6VnkrSE5aZz09":
      date = "2021-04-11";
      break;
    case "N1JycTk0UGE3U2VuZ3hqcG5aQ3pHUT09":
      date = "2021-04-12";
      break;
    case "Z1lSUFhVb0FmS2NYeitZQ0lBZC9tdz09":
      date = "2021-04-13";
      break;
    case "NmxwSjB1ei9xYmNqN1VmWXVham4xZz09":
      date = "2021-04-14";
      break;
    case "RGdKZXBFT2k5bTJvY1FtSVRtNFdudz09":
      date = "2021-04-15";
      break;
    case "NDhEQ1BLN1dudVFWWjMwckFhMGpYZz09":
      date = "2021-04-16";
      break;
    case "Z1JJcTZ0SWdHOWRmWXE1TjFjbDBzZz09":
      date = "2021-04-17";
      break;
    case "emhlWUdtNzNuNnViVlRKZStKNDVQZz09":
      date = "2021-04-18";
      break;
    case "ZFpPSnNZUzF3UUdLY3l3czA2SDBrUT09":
      date = "2021-04-19";
      break;
    case "MmZvQ0dpbVozMDRqOFNLdzdWNGZYdz09":
      date = "2021-04-20";
      break;
    case "SEk3UUNtbHJRZXp2eHlKS2lDZ0ZPQT09":
      date = "2021-04-21";
      break;
    case "VkFxWUllcXdsK1JwSlR6RVZXRThyZz09":
      date = "2021-04-22";
      break;
    case "SFVWNWREa3dCaVBhbVphcXdXcjYwZz09":
      date = "2021-04-23";
      break;
    case "aDJ3L0VlQ0RUNVdaU1ZQbUVNRTl2QT09":
      date = "2021-04-24";
      break;
    case "cW1sUjZsRnhyUitKQTBRL0VmUVJqQT09":
      date = "2021-04-25";
      break;
    case "a2JKc2JTZ1ZabjR4bjh0NUEzZ2NqZz09":
      date = "2021-04-26";
      break;
    case "Ykx4c29OLzk2bjBjTE5scEVrMU9aQT09":
      date = "2021-04-27";
      break;
    case "V0ExMzZ6aHpucFJsUm1PRURQcTk0Zz09":
      date = "2021-04-28";
      break;

    default:
      date = "error";
  }

  if (allowed) {
    store.state.viewingdate = val;
    next();
  } else {
    let today = new Date().toISOString().slice(0, 10);
    let d = new Date();
    d.setDate(d.getDate() - 1);
    let yesterday = d.toISOString().split("T")[0];
    if (date == today || date == yesterday) {
      store.state.allowed = true;
      store.state.viewingdate = val;
      next();
    } else {
      store.state.allowed = false;
      store.state.bookedfor = date;
      let CurrentDate = new Date();
      let GivenDate = new Date(date);

      if (GivenDate > CurrentDate) {
        store.state.earlylate = "early";
      } else if (GivenDate < CurrentDate) {
        store.state.earlylate = "late";
      } else {
        store.state.earlylate = "error";
      }

      next();
    }
  }
});

export default router;
